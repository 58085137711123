export const TabENStyle = {
    color: "#153C72",
    fontFamily: "MHeiHK-Bold",
    fontSize: "1rem",
};

export const TabTCStyle = {
    color: "#153C72",
    fontFamily: "MHeiHK-Bold",
    fontSize: "1.2rem",  
};

export const HighlightTitleStyle = {
    position: "absolute",
    color: "#153C72",
    letterSpacing: "0.1em",
    textAlign: "left",
};

export const HighlightContentStyle = {
    position: "absolute",
    lineHeight: "1.3em",
    left: "8vw",
    color: "#FFF",
    textAlign: "left",
}

// export const  Title =