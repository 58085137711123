import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import './fonts/MHeiHK-Bold.ttf';
import './fonts/MHeiHK-Xbold.ttf';
import './fonts/MStiffHei-HK-Bold.ttf';
import './fonts/Poppins-Medium.otf';

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFCA2D",
    },
    secondary: {
      main: "#153C72",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
