export const TEXT = {
  TC: {
    MENU: {
      HOME: "主頁",
      PACKING: "產品包裝",
      INDICATIONS: "主治症狀",
      PRECAUTIONS: "注意事項",
      DOSAGE: "服量",
    },
    VIDEO_TITLE: "迅速消除頭痛，發熱，傷風感冒引起之喉嚨發炎及一般肌肉痛症",
    DESCRIPTION_TITLE: "成份",
    DESCRIPTION_CONTENT: `
    每片成人克痛藥片含有 Acetylsalicylic Acid（Aspirin）500mg 及 Caffeine Anhydrous 30mg。
    `,
    ACTIONS_TITLE: "藥理作用",
    ACTIONS_CONTENT: `
    醫學證明克痛藥片的配方是非常有效的止痛退燒藥，能夠降低人體對痛楚的敏感度，從而達到止痛的效果，亦能夠舒緩發燒及喉嚨發炎。
    `,
    PRECAUTIONS_TITLE: "注意事項",
    PRECAUTIONS_CONTENT_1A: `
    服用產品前，請先詳細閱讀產品說明，並按照指示使用產品。
    `,
    PRECAUTIONS_CONTENT_1B: `
    請將此藥存放於兒童不易觸及的地方。除醫生指示外，不應給予16歲以下兒童服用此藥物。
    `,
    PRECAUTIONS_CONTENT_1C: `
    以下人士在服用本藥物前，請先向醫生查詢，方可服用：
    `,
    PRECAUTIONS_CONTENT_2A: `・長者`,
    PRECAUTIONS_CONTENT_2B: `・患有高血壓人士`,
    PRECAUTIONS_CONTENT_2C: `・如認為身體有脱水跡象的人士（你可能感到口渴以及口乾症狀）`,
    PRECAUTIONS_CONTENT_2D: `・懷孕或哺乳期的婦女`,
    PRECAUTIONS_CONTENT_2E: `・長期病患者及服食其他藥物（包括成藥）人士`,
    PRECAUTIONS_CONTENT_3A: `
    「雷氏綜合症」：患有水痘或有感冒症狀或其在康復中的兒童及青少年不應服用此藥。
    `,
    PRECAUTIONS_CONTENT_3B: `
    此藥物含有咖啡因，在服用此藥期間，應避免飲用過量含咖啡因的飲品，如茶，咖啡，及其他含咖啡因的飲料。
    `,
    PRECAUTIONS_CONTENT_3C: `
    患有哮喘，胃潰瘍，持續性消化不良或肝病的病人不可服用。
    `,
    PRECAUTIONS_CONTENT_3D: `
    如服用超過指定份量的成人克痛藥片，請立即往醫生處求診。
    `,
    PRECAUTIONS_CONTENT_3E: `
    任何藥物皆有機會引致不良反應，請參考產品說明內列明的不良反應；若服用後感到不適，應請教醫生。
    `,
    DOSAGE_TITLE: "服量",
    DOSAGE_CONTENT_1: `
    ・成人（16歲或以上）：飽肚服，需要時每日3至4次，每次以開水送服1至2片，每隔4至6小時服ー次，24小時內不可服用超過4000毫克阿士匹靈/240毫克咖啡因，即8片成人克痛藥片。請勿相隔少於4小時服用此藥物。`,
    DOSAGE_CONTENT_2: `
    ・如服用成人克痛作止痛用途：不應自行服用超過10日。若病癥持續，應請教醫生。`,
    DOSAGE_CONTENT_3: `
    ・如服用成人克痛作退燒用途：不應自行服用超過3日。若病癥持續，應請教醫生。`,
    STORAGE_TITLE: "貯存方法",
    STORAGE_CONTENT: `
    此藥應貯存在不被陽光直接照射的緊閉容器內。請貯存於25℃以下。
      `,
  },
  EN: {
    MENU: {
      HOME: "Home",
      PACKING: "Product Packing",
      INDICATIONS: "Indications",
      PRECAUTIONS: "Precautions",
      DOSAGE: "Dosage and Administration",
    },
    VIDEO_TITLE:
    "Fast Relief from Headaches, Fever, Throat Inflammation associated with Cold & Flu, and Muscular Pain",
    DESCRIPTION_TITLE: "DESCRIPTION",
    DESCRIPTION_CONTENT: `
    Cortal for Adults contains 500mg Acetylsalicylic Acid (Aspirin) and 30mg Caffeine Anhydrous. 
    `,
    ACTIONS_TITLE: "ACTIONS",
    ACTIONS_CONTENT: `
    Cortal for Adults is a clinically proven analgesic, which produces its analgesia by elevation of the pain threshold. It also relieves fever and throat inflammation. 
    `,
    PRECAUTIONS_TITLE: "PRECAUTIONS",
    PRECAUTIONS_CONTENT_1A: `
    Please read through product description first before taking this medicine, and use it as advised.
    `,
    PRECAUTIONS_CONTENT_1B: `
    Keep this and all medications out of reach of children. This medicine should not be given to children under 16 except on medical advice.`,
    PRECAUTIONS_CONTENT_1C: `
    Take special care and check with your physician before you take this medicine if: 
    `,
    PRECAUTIONS_CONTENT_2A: `・you are elderly`,
    PRECAUTIONS_CONTENT_2B: `・you have high blood pressure`,
    PRECAUTIONS_CONTENT_2C: `・you think you are dehydrated (you may feel thirsty with a dry mouth)`,
    PRECAUTIONS_CONTENT_2D: `・you are pregnant or are breast-feeding`,
    PRECAUTIONS_CONTENT_2E: `・you are suffering from chronic disease or are taking other medicines (including over-the-counter medicines)`,
    PRECAUTIONS_CONTENT_3A: `
    Reye's syndrome: Children & teenagers who have or are recovering from chicken pox or flu-like symptoms should not use this product.`,
    PRECAUTIONS_CONTENT_3B: `
    This medicine contains caffeine. Avoid drinking too many caffeine containing drinks (e.g, tea, coffee and caffeine-containing canned drinks) when taking this medicine.`,
    PRECAUTIONS_CONTENT_3C: `
    This medicine should not be taken by patients with asthma, stomach ulcers, persistent indigestion or liver diseases.`,
    PRECAUTIONS_CONTENT_3D: ` 
    If you take more medicine than recommended, seek medical advice immediately.`,
    PRECAUTIONS_CONTENT_3E: `
    All medicines can have adverse reactions, please refer to product information for list of side-effects; Seek medical advice if you experience anything that worries you.
    `,
    DOSAGE_TITLE: "DOSAGE AND ADMINISTRATION",
    DOSAGE_CONTENT_1: `
    ・Adults (age 16 or above): 1-2 tablets to 
    be swallowed with water, every 4-6 
    hours as required, 3 or 4 times daily as 
    needed. Should be taken with or after 
    food. Do not exceed 8 tablets (aspirin 
    4000mg / caffeine 240mg) in 24 hours. 
    Do not exceed the stated dose and do 
    not take more frequently than every 4 hours.`,
    DOSAGE_CONTENT_2: `
    ・For pain relief: Do not use for more 
    than 10 days. If your symptoms persist 
    or worsen, please consult your doctor. 
        `,
    DOSAGE_CONTENT_3: `
    ・For relief of fever: Do not use for more 
    than 3 days. If your symptoms persist
    or worsen, please consult your doctor. 
    `,
    STORAGE_TITLE: "STORAGE CONDITIONS",
    STORAGE_CONTENT: `
      Store below 25℃. Store 
      in a well-closed container 
      protected from sunlight.
      `,
  },
};
