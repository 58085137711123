import * as React from "react";
import logo from "./logos/web_v6_trimversion-17.png";
import "./App.css";
import {
  Button,
  Box,
  Tab,
  Tabs,
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
  Autocomplete,
  Dialog,
  IconButton,
  Slide,
} from "@mui/material";

import ENHomeImage from "./images/EN/web_v6_trimversion-15.png";
import ENPackingImage from "./images/EN/web_v6_trimversion-09.png";
import ENIndicationsImage from "./images/EN/web_v6_trimversion-10.png";
import ENPrecautionsImage from "./images/EN/web_v6_trimversion-11.png";
import ENDosageImage from "./images/EN/web_v6_trimversion-12.png";
import TCHomeImage from "./images/TC/web_v6_trimversion-02.png";
import TCPackingImage from "./images/TC/web_v6_trimversion-03.png";
import TCIndicationsImage from "./images/TC/web_v6_trimversion-04.png";
import TCPrecautionsImage from "./images/TC/web_v6_trimversion-05.png";
import TCDosageImage from "./images/TC/web_v6_trimversion-06.png";

// material icons
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
// data
import { TEXT } from "./data/text";
import Highlighter from "react-highlight-words";
import { en_options, tc_options } from "./data/autocomplete-options";

// const options = ["Fever", "Headache"];
// import Menu from "./components/Menu";
import styled from "styled-components";
import { TabENStyle, TabTCStyle } from "./styles/styles";

function App() {
  const [section, setSection] = React.useState("home");
  const [language, setLanguage] = React.useState("TC");
  const [searchText, setSearchText] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  const homeRef = React.useRef();
  const packingRef = React.useRef();
  const indicationsRef = React.useRef();
  const precautionsRef = React.useRef();
  const dosageRef = React.useRef();

  const scrollTo = (ref) => {
    console.log("scroll to ", ref);
    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  
  const handleChangeSection = (event, newValue) => {
    setSection(newValue);
    switch (newValue) {
      case "packing":
        scrollTo(packingRef);
        break;
      case "indications":
        scrollTo(indicationsRef);
        break;
      case "precautions":
        scrollTo(precautionsRef);
        break;
      case "dosage":
        scrollTo(dosageRef);
        break;
      default:
        scrollTo(homeRef);
    }
  };

  const handleChangeLanguage = () => {
    language === "EN" ? setLanguage("TC") : setLanguage("EN");
  };

  // const handleChangeSearch = (event) => {
  //   const searchText = event.target.value;
  //   setSearchText(searchText);
  // };

  // const gotoText = (text) => {
  //   console.log("goto", text);
  //   const result = document.getElementsByTagName("mark");
  //   // The above flatMap creates a [text, employeeName, text, employeeName, text, employeeName]-pattern. We need to remove the last superfluous employeeName.
  // };

  const handleCloseDialog = () => setOpenDialog(false);

  return (
    <div className="App">
      {/* menu */}
      {/* <Menu
        section={section}
        handleChangeSection={handleChangeSection}
        language={handleChangeLanguage}
        setSearchText={setSearchText}
        gotoText={gotoText}
      /> */}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: "#FFCA2D",
          height: { xs: "50px", md: "150px" },
        }}
      >
        <AppBar position="fixed">
          <Toolbar sx={{ height: { xs: "50px", md: "150px" } }}>
            <img
              src={logo}
              alt="logo"
              style={{ width: "120px", maxWidth: "20vw", margin: "0 30px" }}
            />

            <Tabs
              sx={{ display: { xs: "none", md: "block" } }}
              value={section}
              onChange={handleChangeSection}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="menu bar"
            >
              <Tab
                value="home"
                label={
                  language === "EN" ? TEXT.EN.MENU.HOME : TEXT.TC.MENU.HOME
                }
                style={language === "EN" ? TabENStyle : TabTCStyle}
              />
              <Tab
                value="packing"
                label={
                  language === "EN"
                    ? TEXT.EN.MENU.PACKING
                    : TEXT.TC.MENU.PACKING
                }
                style={language === "EN" ? TabENStyle : TabTCStyle}
              />
              <Tab
                value="indications"
                label={
                  language === "EN"
                    ? TEXT.EN.MENU.INDICATIONS
                    : TEXT.TC.MENU.INDICATIONS
                }
                style={language === "EN" ? TabENStyle : TabTCStyle}
              />
              <Tab
                value="precautions"
                label={
                  language === "EN"
                    ? TEXT.EN.MENU.PRECAUTIONS
                    : TEXT.TC.MENU.PRECAUTIONS
                }
                style={language === "EN" ? TabENStyle : TabTCStyle}
              />
              <Tab
                value="dosage"
                label={
                  language === "EN" ? TEXT.EN.MENU.DOSAGE : TEXT.TC.MENU.DOSAGE
                }
                style={language === "EN" ? TabENStyle : TabTCStyle}
              />
            </Tabs>

            <Box sx={{ flexGrow: 1 }}></Box>

            <Button
              variant="outline"
              onClick={handleChangeLanguage}
              style={{
                color: "#153C72",
                fontFamily: "MHeiHK-Bold",
                fontSize: "1.2rem",
              }}
            >
              {language === "EN" ? "中" : "EN"}
            </Button>
            <IconButton
              sx={{
                display: { xs: "block", md: "none" },
              }}
              color="secondary"
              aria-label="menu"
              onClick={() => setOpenDialog(!openDialog)}
            >
              <MenuIcon />
            </IconButton>
            {/* <Autocomplete
              // disablePortal
              id="autocomplete-searchbar"
              options={language === "EN" ? en_options : tc_options}
              onChange={(event, value) => {
                if (value === null) {
                  // close
                  console.log("autocomplete no select");
                  setSearchText("");
                } else {
                  console.log("autocomplete select", value);
                  setSearchText(value);
                  gotoText(value);
                }
              }}
              // onClose={() => { console.log('close')}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      marginTop: "10px",
                      marginBottom: "10px",
                      borderRadius: "30px",
                      width: "280px",
                      height: "60px",
                      backgroundColor: "#FFF",
                    },
                  }}
                  // id="search-bar"
                  placeholder="Search"
                  variant="outlined"
                  color="secondary"
                  // label="Search"
                  // onChange={handleChangeSearch}
                  //
                  // style={{ borderRadius: "50%" }}
                />
              )}
            /> */}
          </Toolbar>
        </AppBar>
      </Box>

      {/* adjust for fixed menu bar */}
      {/* <Box sx={{ display: {xs: 'block', md: 'none'}, height: "0px" }}></Box> */}

      {/* home */}
      <Box
        sx={{ width: "100%", marginBottom: "-10px" }}
        ref={homeRef}
        id="home-section"
      >
        <img
          src={ENHomeImage}
          alt="Home"
          style={{ margin: 0, padding: 0, width: "100%" }}
        />
      </Box>

      {/* video */}
      <Box sx={{ width: "100%", marginBottom: "-10px", position: "relative" }}>
        <img
          src={language === "EN" ? ENPackingImage : TCPackingImage}
          alt="Packing"
          style={{ width: "100%" }}
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            top: language === "EN" ? "5.6%" : "7.4%",
            left: language === "EN" ? "18vw" : "15vw",
            width: language === "EN" ? "65vw" : "70vw",
            color: "#153C72",
            fontSize: language === "EN" ? "2vw" : "2.4vw",
            // fontWeight: language === "EN" ? 700 : 700,
            fontFamily: language === "EN" ? "MStiffHei" : "MStiffHei",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN" ? TEXT.EN.VIDEO_TITLE : TEXT.TC.VIDEO_TITLE
          }
        />
        {/* tvc */}
        <div
          style={{
            width: "100vw",
            height: "90%",
            position: "absolute",
            top: "10%",
            left: "0",
            margin: "0 auto",
            // backgroundColor: "red",
          }}
        >
          {/* <video
            width="80%"
            height="80%"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginLeft: "auto",
              marginRight: "auto",
              // -webkit-transform: translate(-50%, -50%)
              transform: "translate(-50%, -50%)",
              // position: 'absolute', margin: 0, padding: 0
            }}
            controls
            autoplay
          >
            <source src={CortalTVC} type="video/mp4" />
          </video> */}
          <iframe
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginLeft: "auto",
              marginRight: "auto",
              // -webkit-transform: translate(-50%, -50%)
              transform: "translate(-50%, -50%)",
            }}
            width="75%"
            height="75%"
            src="https://www.youtube.com/embed/Yyi_vAPbczI?rel=0&autoplay=1&loop=1&playlist=Yyi_vAPbczI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Box>

      {/* packing */}
      <Box
        sx={{ width: "100%", marginBottom: "-10px" }}
        ref={packingRef}
        id="packing-section"
      >
        <img
          src={language === "EN" ? ENIndicationsImage : TCIndicationsImage}
          alt="Indications"
          style={{ width: "100%" }}
        />
      </Box>

      {/* precautions */}
      <Box
        sx={{ width: "100%", marginBottom: "-10px" }}
        ref={indicationsRef}
        id="indications-section"
      >
        <img
          src={language === "EN" ? ENPrecautionsImage : TCPrecautionsImage}
          alt="Precautions"
          style={{ width: "100%" }}
        />
      </Box>

      <Box sx={{ width: "100%", marginBottom: "-10px", position: "relative" }}>
        <img
          src={language === "EN" ? ENDosageImage : TCDosageImage}
          alt="Dosage"
          style={{ width: "100%" }}
        />

        {/* description */}
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            top: language === "EN" ? "1.8%" : "2%",
            left: language === "EN" ? "18vw" : "20vw",
            color: "#153C72",
            fontSize: language === "EN" ? "2.8vw" : "3.2vw",
            letterSpacing: "0.1em",
            fontFamily: language === "EN" ? "MHeiHK-XBold" : "MHeiHK-XBold",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.DESCRIPTION_TITLE
              : TEXT.TC.DESCRIPTION_TITLE
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "6.5%" : "7.4%",
            left: "8vw",
            width: language === "EN" ? "70vw" : "60vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.DESCRIPTION_CONTENT
              : TEXT.TC.DESCRIPTION_CONTENT
          }
        />

        {/* actions */}
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            top: language === "EN" ? "11.6%" : "13.2%",
            left: language === "EN" ? "18vw" : "17vw",
            color: "#153C72",
            fontSize: language === "EN" ? "2.8vw" : "3.2vw",
            letterSpacing: "0.1em",
            fontFamily: language === "EN" ? "MHeiHK-XBold" : "MHeiHK-XBold",
          }}
          textToHighlight={
            language === "EN" ? TEXT.EN.ACTIONS_TITLE : TEXT.TC.ACTIONS_TITLE
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "16.1%" : "18.8%",
            left: "8vw",
            width: language === "EN" ? "75vw" : "75vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.ACTIONS_CONTENT
              : TEXT.TC.ACTIONS_CONTENT
          }
        />

        {/* precautions */}
        <div
          id="precautions-section"
          ref={precautionsRef}
          style={{
            position: "absolute",
            top: language === "EN" ? "24%" : "22%",
          }}
        ></div>
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            top: language === "EN" ? "21.5%" : "24.6%",
            left: language === "EN" ? "18vw" : "17vw",
            color: "#153C72",
            fontSize: language === "EN" ? "2.8vw" : "3.2vw",
            letterSpacing: "0.1em",
            fontFamily: language === "EN" ? "MHeiHK-XBold" : "MHeiHK-XBold",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_TITLE
              : TEXT.TC.PRECAUTIONS_TITLE
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "26%" : "30%",
            left: "8vw",
            width: language === "EN" ? "85vw" : "83vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_1A
              : TEXT.TC.PRECAUTIONS_CONTENT_1A
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "27.3%" : "31.4%",
            left: "8vw",
            width: language === "EN" ? "85vw" : "83vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_1B
              : TEXT.TC.PRECAUTIONS_CONTENT_1B
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "29.9%" : "32.9%",
            left: "8vw",
            width: language === "EN" ? "85vw" : "83vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_1C
              : TEXT.TC.PRECAUTIONS_CONTENT_1C
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "31.3%" : "34.5%",
            left: "8vw",
            width: language === "EN" ? "80vw" : "75vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_2A
              : TEXT.TC.PRECAUTIONS_CONTENT_2A
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "32.6%" : "36.1%",
            left: "8vw",
            width: language === "EN" ? "80vw" : "75vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_2B
              : TEXT.TC.PRECAUTIONS_CONTENT_2B
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "34.1%" : "37.7%",
            left: "8vw",
            width: language === "EN" ? "80vw" : "75vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_2C
              : TEXT.TC.PRECAUTIONS_CONTENT_2C
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "35.7%" : "39.3%",
            left: "8vw",
            width: language === "EN" ? "80vw" : "75vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_2D
              : TEXT.TC.PRECAUTIONS_CONTENT_2D
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "37.3%" : "40.9%",
            left: "8vw",
            width: language === "EN" ? "70vw" : "75vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_2E
              : TEXT.TC.PRECAUTIONS_CONTENT_2E
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "40.1%" : "42.6%",
            left: "8vw",
            width: language === "EN" ? "85vw" : "83vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_3A
              : TEXT.TC.PRECAUTIONS_CONTENT_3A
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "42.8%" : "44.1%",
            left: "8vw",
            width: language === "EN" ? "85vw" : "83vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_3B
              : TEXT.TC.PRECAUTIONS_CONTENT_3B
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "45.4%" : "46.9%",
            left: "8vw",
            width: language === "EN" ? "85vw" : "83vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_3C
              : TEXT.TC.PRECAUTIONS_CONTENT_3C
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "48%" : "48.3%",
            left: "8vw",
            width: language === "EN" ? "85vw" : "83vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_3D
              : TEXT.TC.PRECAUTIONS_CONTENT_3D
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "49.4%" : "49.8%",
            left: "8vw",
            width: language === "EN" ? "85vw" : "83vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.PRECAUTIONS_CONTENT_3E
              : TEXT.TC.PRECAUTIONS_CONTENT_3E
          }
        />
        {/* dosage */}
        <div
          id="dosage-section"
          ref={dosageRef}
          style={{
            position: "absolute",
            top: language === "EN" ? "55%" : "53%",
          }}
        ></div>
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            top: language === "EN" ? "55%" : "55.6%",
            left: language === "EN" ? "18vw" : "20vw",
            color: "#153C72",
            fontSize: language === "EN" ? "2.8vw" : "3.2vw",
            letterSpacing: "0.1em",
            fontFamily: language === "EN" ? "MHeiHK-XBold" : "MHeiHK-XBold",
          }}
          textToHighlight={
            language === "EN" ? TEXT.EN.DOSAGE_TITLE : TEXT.TC.DOSAGE_TITLE
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "60%" : "61%",
            left: "8vw",
            width: language === "EN" ? "40vw" : "39vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.DOSAGE_CONTENT_1
              : TEXT.TC.DOSAGE_CONTENT_1
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "70%" : "69.8%",
            left: "8vw",
            width: language === "EN" ? "40vw" : "39vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.DOSAGE_CONTENT_2
              : TEXT.TC.DOSAGE_CONTENT_2
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "74%" : "73.3%",
            left: "8vw",
            width: language === "EN" ? "40vw" : "39vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.DOSAGE_CONTENT_3
              : TEXT.TC.DOSAGE_CONTENT_3
          }
        />

        {/* storage */}
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            top: language === "EN" ? "81.8%" : "79%",
            left: language === "EN" ? "18vw" : "17vw",
            color: "#153C72",
            fontSize: language === "EN" ? "2.8vw" : "3.2vw",
            letterSpacing: "0.1em",
            fontFamily: language === "EN" ? "MHeiHK-XBold" : "MHeiHK-XBold",
          }}
          textToHighlight={
            language === "EN" ? TEXT.EN.STORAGE_TITLE : TEXT.TC.STORAGE_TITLE
          }
        />
        <Highlighter
          searchWords={[searchText]}
          autoEscape={true}
          style={{
            position: "absolute",
            lineHeight: "1.3em",
            top: language === "EN" ? "86.2%" : "84.8%",
            left: "8vw",
            width: language === "EN" ? "25vw" : "28vw",
            color: "#FFF",
            fontSize: language === "EN" ? "2vw" : "2vw",
            fontFamily: language === "EN" ? "MHeiHK-Bold" : "MHeiHK-Bold",
            textAlign: "left",
          }}
          textToHighlight={
            language === "EN"
              ? TEXT.EN.STORAGE_CONTENT
              : TEXT.TC.STORAGE_CONTENT
          }
        />
      </Box>

      <Dialog
        onClose={handleCloseDialog}
        open={openDialog}
        // transitionComponent={Transition}
        fullScreen
        sx={{
          color: "primary.main",
          backgroundColor: "#FFCA2D",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "#FFCA2D",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box m={2}>
            <IconButton onClick={handleCloseDialog} sx={{ width: "100%" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box m={2}>
            <Button
              variant="text"
              color="secondary"
              sx={{
                width: "100%",
                fontFamily: "MHeiHK-Bold",
                fontSize: "1.4rem",
              }}
              onClick={() => {
                handleCloseDialog();
                window.scrollTo({ top: 0 });
              }}
            >
              {language === "EN" ? TEXT.EN.MENU.HOME : TEXT.TC.MENU.HOME}
            </Button>
          </Box>
          <Box m={2}>
            <Button
              variant="text"
              color="secondary"
              sx={{
                width: "100%",
                fontFamily: "MHeiHK-Bold",
                fontSize: "1.4rem",
              }}
              onClick={() => {
                handleCloseDialog();
                // handleChangeSection(null, "packing");
                window.scrollTo({ top: 450 });

                // document.getElementsByTagName("packing-section").scrollIntoView();
              }}
            >
              {language === "EN" ? TEXT.EN.MENU.PACKING : TEXT.TC.MENU.PACKING}
            </Button>
          </Box>
          <Box m={2}>
            <Button
              variant="text"
              color="secondary"
              sx={{
                width: "100%",
                fontFamily: "MHeiHK-Bold",
                fontSize: "1.4rem",
              }}
              onClick={() => {
                handleCloseDialog();

                window.scrollTo({ top: 650 });
                // scrollTo(indicationsRef);
                // handleChangeSection(null, 'indications');
              }}
            >
              {language === "EN"
                ? TEXT.EN.MENU.INDICATIONS
                : TEXT.TC.MENU.INDICATIONS}
            </Button>
          </Box>
          <Box m={2}>
            <Button
              variant="text"
              color="secondary"
              sx={{
                width: "100%",
                fontFamily: "MHeiHK-Bold",
                fontSize: "1.4rem",
              }}
              onClick={() => {
                // scrollTo(precautionsRef);
                handleCloseDialog();
                // handleChangeSection(null, "precautions");
                window.scrollTo({ top: 960 });
              }}
            >
              {language === "EN"
                ? TEXT.EN.MENU.PRECAUTIONS
                : TEXT.TC.MENU.PRECAUTIONS}
            </Button>
          </Box>
          <Box m={2}>
            <Button
              variant="text"
              color="secondary"
              sx={{
                width: "100%",
                fontFamily: "MHeiHK-Bold",
                fontSize: "1.4rem",
              }}
              onClick={() => {
                handleCloseDialog();
                // scrollTo(dosageRef);
                // const offset = document.getElementById(
                //   "dosage-section"
                // ).offsetTop;
                // console.log("dosage-section", offset);
                window.scrollTo({ top: 960 });
                // scrollTo(indicationsRef);
                // console.log('go to dosage');
                // const offset = document.getElementById('dosage-section');
                // console.log('offset', offset)
                // window.scrollTo({ top: 300});
                // handleChangeSection(null, "dosage");
              }}
            >
              {language === "EN" ? TEXT.EN.MENU.DOSAGE : TEXT.TC.MENU.DOSAGE}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}

export default App;
